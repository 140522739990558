import './css/privacypolicy.css';
import logo from "./resources/AI4PublicPolicy VPME logo 300.png";
function Terms() {

    return (
        <div>
            <div style={{ height: "75px", border: "rgb(227, 227, 227) 0.5px solid", borderRadius: "0px 0px 3px 3px", background: "rgb(240, 240, 250)", display: "flex", alignItems: "center", justifyContent: "flex-start", margin: "-8px" }}>
                <img src={logo} alt={"VPME logo"} style={{ width: "205px", marginLeft: "30px" }} />
            </div>
            <h1>Terms and Conditions</h1>
            <p>Welcome to AI4PublicPolicy VPME (“the Website”). By accessing or using the Website, you agree to comply with and be bound by the following terms and conditions of use.</p>

            <h2>Definitions</h2>
            <ul>
                <li>‘AI4PublicPolicy’ is a Horizon 2020 project funded under Grant Agreement, No. 101004480;</li>
                <li>‘Public Policy Data’ means any digital representation of acts, facts or information and any compilation of such acts, facts or information, including in the form of sound, visual or audio-visual recording that relate to public policies, for instance, in the fields of transportation, energy or water management;</li>
                <li>‘Data Holder’ means a public body that has the right or obligation, in accordance with the law or internal policy, to use and make available Public Policy Data, including, where contractually agreed, product data or related service data which it has retrieved or generated during the provision of a Related Service;</li>
                <li>‘Data Recipient’ means a natural or legal person for purposes which are related to that entities’ mandate, to whom the Data Holder makes the Public Policy Data available, including a third party following a request by the entitled Data Recipient;</li>
                <li>‘Related Service’ means a digital service, other than an electronic communications service, including software, which is connected with the product at the time of the purchase, rent or lease in such a way that its absence would prevent the connected product from performing one or more of its functions, or which is subsequently connected to the product by the manufacturer or a third party to add to, update or adapt the functions of the connected product;</li>
                <li>‘GFT is a global digital transformation company that introduces business agility at scale for the world's leading finance, insurance and manufacturing companies.</li>
                <li>‘Website’ means the AI4PublicPolicy VPME, assessable from: &nbsp;<a href="https://vpme.fedcloud-tf.fedcloud.eu">https://vpme.fedcloud-tf.fedcloud.eu</a></li>
            </ul>

            <h2>Preliminary</h2>
            <ul>
                <li>GFT is the AI4PublicPolicy project partner currently in charge of developing and administering the website, for the purposes stated in the AI4PublicPolicy’s Grant Agreement, where the AI4PublicPolicy’s VPME is hosted. This current arrangement reflects the role and commitments undertaken by GFT in the Grant Agreement.</li>
                <li>Accordingly, GFT keeps the website alive for a period (minimum of 12 months) after the project ends. GFT does not have any claim on the information indexed on the website.</li>
            </ul>

            <h2>1. Scope</h2>
            <p>The Website is meant to provide access to Public Policy Data and Related Services to facilitate exchange of information and knowledge among policy makers and civil servants. The Website is NOT meant to provide data or services to consumers.</p>

            <h2>2. Acceptance of Terms</h2>
            <ul>
                <li>By accessing or using the Website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</li>
                <li>If you do not agree with any of these terms, you are prohibited from using or accessing this Website.</li>
            </ul>

            <h2>3. Data VPME</h2>
            <ul>
                <li>Data Holders are the sole entity responsible for the Public Policy Data they make available on the Website.</li>
                <li>Data Holders shall make Public Policy Data available pursuant their legal obligations and internal policy.</li>
                <li>In case the Public Policy Data made available by the Data Holder includes personal data, as defined in Art 4, point (1), of Regulation (EU) 2016/679 (GDPR), the Data Holder shall take all the appropriate measures in order to comply with the law and avoid any unnecessary risk.</li>
                <li>AI4PublicPolicy does not claim ownership of any Public Policy Data or Related Services made available on the Website but reserves the right to moderate or remove content that violates these Terms and Conditions, or the law.</li>
            </ul>

            <h2>4. Intellectual Property</h2>
            <ul>
                <li>Public Policy Data and Related Services on the Website shall be made available to Data Recipients in accordance with the licensing terms established by each Data Holder.</li>
                <li>Data Recipients may only use the Public Policy Data in accordance with the licensing terms established by the Data Holders, these Terms and Conditions, and the law.</li>
                <li>Data Holder retains all the rights on the Public Policy Data they make available on the Website, but by using the Website, Data Holder grants AI4PublicPolicy a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, translate, distribute, and display the Public Policy Data.</li>
            </ul>

            <h2>5. Expected Conduct</h2>
            <ul>
                <li>Data Recipient agrees not to use the Website for any unlawful or prohibited purpose. Data Recipient shall behave in accordance with these Terms and Conditions, and pursuant to the applicable laws.</li>
                <li>Both Data Holders and Data Recipients are responsible for maintaining the confidentiality of their accounts and passwords and for restricting access to their account, thus to ensure a high level of cybersecurity protection.</li>
            </ul>

            <h2>6. Limitation of Liability</h2>
            <p>AI4PublicPolicy, its owners, employees, or affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of this Website.</p>

            <h2>7. Modifications</h2>
            <p>AI4PublicPolicy reserves the right to revise these terms and conditions at any time without prior notice. By using this Website, you agree to be bound by the current version of these terms and conditions.</p>

            <h2>8. Governing Law</h2>
            <p>These terms and conditions shall be governed by and construed in accordance with the laws [insert country]. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].</p>

            <p>By using the Website, you agree to these terms and conditions. If you do not agree with any part of these terms and conditions, please do not use the Website.</p>

            <footer style={{ height: "70px", border: "rgb(227, 227, 227) 0.5px solid", borderRadius: "5px 5px 0px 0px", background: "rgb(240, 240, 250)", display: "flex", alignItems: "center", justifyContent: "center", margin: "-8px" }}>
                <p style={{ color: "grey" }}>Copyright © 2024 AI4PublicPolicy Project. All rights reserved.</p>
            </footer>
        </div>
    )

}

export default Terms;