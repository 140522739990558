import './css/privacypolicy.css';
import logo from "./resources/AI4PublicPolicy VPME logo 300.png";
function PrivacyPolicy() {

    return (
        <div>
            <div style={{ height: "75px", border: "rgb(227, 227, 227) 0.5px solid", borderRadius: "0px 0px 3px 3px", background: "rgb(240, 240, 250)", display: "flex", alignItems: "center", justifyContent: "flex-start", margin: "-8px" }}>
                <img src={logo} alt={"VPME logo"} style={{width:"205px", marginLeft:"30px"}}/>
            </div>
            <h1>Privacy & Cookie Policy</h1>
            <p>Last updated: 18 December 2023</p>

            <h2>Cookie Policy for AI4PublicPolicy VPME</h2>
            <p>This is the Privacy & Cookie Policy for AI4PublicPolicy VPME, accessible from &nbsp;<a href="https://vpme.fedcloud-tf.fedcloud.eu">https://vpme.fedcloud-tf.fedcloud.eu</a></p>

            <h2>What Are Cookies</h2>
            <p>Cookies are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or ‘break’ certain elements of the sites functionality.</p>

            <h2>How We Use Cookies</h2>
            <p>We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>

            <h2>Disabling Cookies</h2>
            <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore it is recommended that you do not disable cookies. This Cookies Policy was created with the help of the Cookies Policy Generator.</p>

            <h2>The Cookies We Set</h2>
            <ul>
                <li>Account related cookies - If you create an account with us, then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out; however, in some cases, they may remain afterward to remember your site preferences when logged out.</li>
            </ul>

            <h2>Third Party Cookies</h2>
            <p>In some special cases, we also use cookies provided by trusted third parties. The following section details which third-party cookies you might encounter through this site.</p>
            <ul>
                <li>Third party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit which helps us to understand how we can improve the site for you.</li>
            </ul>

            <h2>More Information</h2>
            <p>Hopefully that has clarified things for you, and as was previously mentioned if there is something that you aren’t sure whether you need or not it’s usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>
            <p>For more general information on cookies, please read the Cookies Policy article.</p>

            <h2>Contact Information</h2>
            <p>If you are still looking for more information then you can contact us through one of our preferred contact methods:</p>
            <ul>
                <li>Email: <a href="mailto:info@ai4publicpolicy.eu">info@ai4publicpolicy.eu</a></li>
            </ul>
            <footer style={{ height: "70px", border: "rgb(227, 227, 227) 0.5px solid", borderRadius: "5px 5px 0px 0px", background: "rgb(240, 240, 250)", display: "flex", alignItems: "center", justifyContent: "center", margin: "-8px" }}>
                <p style={{ color: "grey" }}>Copyright © 2024 AI4PublicPolicy Project. All rights reserved.</p>
            </footer>
        </div>
    )
}

export default PrivacyPolicy;