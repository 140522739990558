import Joinvo from "./Joinvo";
import Login from "./Login";
// import Login from "./Login copy";
import Noise from "./Noise";
import Welcome from "./Welcome";
import React from 'react';
import Map from "./Map";

import { HashRouter, BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";


function App() {
  return (
    <div className="App">
      {/*  BrowserRouter */}
       <HashRouter >
          <Routes>
            <Route path="/noise" element={<Noise/>} />
            <Route path="/welcome" element={<Welcome/>}/>
            <Route exact path="/" element={<Login/> } />
            <Route path="/joinvo" element={<Joinvo/>} />
            <Route path='/terms'  element={<Terms/>}/>
            <Route path='/privacy'  element={<PrivacyPolicy/>}/>
          </Routes>
        </HashRouter >
    </div>
  );
}

export default App;


