import { Helmet } from "react-helmet";
import './css/welcome.css';
import logo from "./resources/AI4PublicPolicy VPME logo 300.png";
import policy from "./resources/p2_policies.png"
import datasets from "./resources/p2_datasets.png";
import policyExtract from "./resources/p2_policy_extraction.png";
import policyEval from "./resources/p2_policy_evaluation.png";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import jwt_decode from "jwt-decode";
import { useState } from "react";
import React from 'react';

function Welcome() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['user']);
    const [role, setRole] = useState("");

    setCookie('tokenCookie', localStorage.getItem('myToken'), { path: 'http://46.101.246.150/policyevaluation#/policyList' });


    function policyEvalClick() {
        const timeout = setTimeout(() => {
            window.location = ("http://46.101.246.150/policyevaluation#/policyList");
        }, 1000);

        return () => clearTimeout(timeout);
    }

    const jwt_decoded = jwt_decode(localStorage.getItem("myToken"));
    const date = new Date();
    const jwtCurrentTime = Math.floor(date.getTime() / 1000);
    if (jwt_decoded.exp - jwtCurrentTime <= 0) {
        alert("Your Token has expired \nYou will be redirected to the Login page")
        window.location = ("https://vpme.fedcloud-tf.fedcloud.eu/");
    }

   
    fetch('https://aai-demo.egi.eu/auth/realms/egi/protocol/openid-connect/userinfo', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("myToken")}`,
            },
        })
        .then((res) => res.json())
        .then((post) => {
            const text = post.eduperson_entitlement[0];
            const splitArray = text.split("=")
            if (splitArray[1].includes("ai expert"))
            {
                setRole("AI Expert")
            }
        })
        .catch((err) => {

        });

    return (
        <html>
            <Helmet>
                {/* <link rel="stylesheet" type="text/css" href="../css/welcome.css"> */}
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css" />
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
            </Helmet>
            <body onunload="">
                <div id="ivsru" className="gjs-row nav-background">
                    <nav id="ig6li-2" draggable="true" data-highlightable="1" className="navbar navbar-expand-lg center-website">
                        <div id="irlhh-2" draggable="true" data-highlightable="1" className="container">
                            <div id="navbarSupportedContent-2-2" draggable="true" data-highlightable="1" className="navbar-collapse">
                                <ul id="ip14k-2" draggable="true" data-highlightable="1" className="navbar-nav me-auto classRule-ip14k-2-ai4pp-welcome">
                                    <li id="idf3t-2" draggable="true" data-highlightable="1" aria-current="page" className="nav-link menu-text-navbar pointer" onClick={() => window.location = "https://ai4publicpolicy.eu/"}><img src={logo} alt={"VPME logo"} className="pointer classRule-iumrf-ai4pp-welcome" /></li>
                                    <li id="idf3t-2" draggable="true" data-highlightable="1" aria-current="page" className="nav-item nav-link menu-text-navbar-link pointer" >Home</li>
                                    <li id="i5l98-2" draggable="true" data-highlightable="1" aria-current="page" className="nav-item nav-link menu-text-navbar-link pointer" onClick={() => window.location = "https://vpme.fedcloud-tf.fedcloud.eu/d-p/datasets"}>Datasets</li>
                                    <li id="iioir-2" draggable="true" data-highlightable="1" role="button" aria-expanded="false" href="#" className="nav-item nav-link menu-text-navbar-link pointer" onClick={() => window.location = "https://vpme.fedcloud-tf.fedcloud.eu/d-p/policies"}>Policies</li>
                                    {/* https://policyevaluation.ai4publicpolicy.eu/projects */}
                                    <li id="ijpxv-2" draggable="true" data-highlightable="1" className="nav-item nav-link menu-text-navbar-link pointer" onClick={() => window.location = "https://vpme.fedcloud-tf.fedcloud.eu/extraction/"}>Policy Extraction</li>
                                    {/* https://policyevaluation.ai4publicpolicy.eu/policyevaluation#/ */}
                                    <li id="ijpxv-2" draggable="true" data-highlightable="1" className="nav-item nav-link menu-text-navbar-link pointer" onClick={() => window.location = "https://vpme.fedcloud-tf.fedcloud.eu/evaluation/policyList"}>Policy Evaluation</li>
                                </ul>
                                <input type="text" required="true" id="ig06b-2" draggable="true" autocomplete="off" className="form-control classRule-ig06b-2-ai4pp-welcome" />
                                <div id="ilk9s-2" draggable="true" data-highlightable="1" className="btn pointer bi archive-btn classRule-ilk9s-2-ai4pp-welcome">Search
                                </div>
                                <form method="get" id="i892i-2" draggable="true" data-highlightable="1" className="d-flex classRule-i892i-ai4pp-Page1 classRule-i892i-2-ai4pp-welcome">
                                </form>
                            </div>
                        </div>
                    </nav>
                </div>
                <div id="ik656" className="gjs-row no-margin center-website">
                    <div id="iaqv1" className="gjs-cell"><img id="iumrk" alt={'logo img'} src={logo} className="pointer classRule-iumrk-ai4pp-welcome" />
                    </div>
                </div>
                <div id="i2hk3" className="gjs-row no-margin center-website classRule-i2hk3-ai4pp-welcome">
                    <div id="i6vnk" className="gjs-cell classRule-i6vnk-ai4pp-welcome">
                        <div id="iaz82" className="title classRule-iaz82-ai4pp-welcome">WELCOME
                        </div>
                        <div id="ihyfv" className="subtitle classRule-ihyfv-ai4pp-welcome">to AI4PublicPolicy policy management environment
                        </div>
                    </div>
                </div>
                <div id="ixkng" className="gjs-row no-margin center-website">
                    {role!=="AI Expert"?<div><div id="iiufg" className="gjs-cell classRule-iiufg-ai4pp-Page1">
                        <div onClick={() => { window.location = "https://vpme.fedcloud-tf.fedcloud.eu/d-p/datasets"}}><img id="ifvw9" alt={'datasets img'} src={datasets} className="pointer center-image" /></div>
                        <div id="iyk8y" className="classRule-inwco-ai4pp-welcome">
                            Datasets
                        </div>
                    </div>
                    <div id="iypr9" className="gjs-cell classRule-iypr9-ai4pp-Page1">
                        <img id="iurup" src={policy} alt={'policy img'} className="pointer center-image" onClick={() => { window.location = "https://vpme.fedcloud-tf.fedcloud.eu/d-p/policies"}} />
                        <div id="istjl" className="classRule-inwco-ai4pp-welcome">Policies
                        </div>
                    </div>
                    <div id="i8yco" className="gjs-cell classRule-i8yco-ai4pp-Page1" onClick={() => { window.location = "https://vpme.fedcloud-tf.fedcloud.eu/extraction/" }}>
                        <img id="inrtk" src={policyExtract} alt={"policy extraction img"} className="pointer center-image" />
                        <div id="inwco" className="classRule-inwco-ai4pp-welcome">Policy Extraction
                        </div>
                    </div>
                    <div id="ii3k3" className="gjs-cell classRule-ii3k3-ai4pp-Page1" onClick={() => window.location = "https://vpme.fedcloud-tf.fedcloud.eu/evaluation/policyList"}>
                        <img id="ivqqh" src={policyEval} alt={'policy evaluation img'} className="pointer center-image" />
                        <div id="iracl" className="classRule-inwco-ai4pp-welcome" >Policy Evaluation
                        </div>
                    </div>
                    </div>
                    :
                    <div><div id="iiufg" className="gjs-cell classRule-iiufg-ai4pp-Page1">
                        <div onClick={() => { window.location = "https://vpme.fedcloud-tf.fedcloud.eu/d-p/dataset"}}><img id="ifvw9" alt={'datasets img'} src={datasets} className="pointer center-image" /></div>
                        <div id="iyk8y" className="classRule-inwco-ai4pp-welcome">
                            Datasets
                        </div>
                    </div>
                    <div id="i8yco" className="gjs-cell classRule-i8yco-ai4pp-Page1" onClick={() => { window.location = "https://vpme.fedcloud-tf.fedcloud.eu/extraction/" }}> 
                        <img id="inrtk" src={policyExtract} alt={"policy extraction img"} className="pointer center-image" />
                        <div id="inwco" className="classRule-inwco-ai4pp-welcome">Policy Extraction
                        </div>
                    </div>
                    </div>}
                </div>
                <footer id="i90l5" className="py-5 bg-dark footer">
                    <div id="ifyag" className="container classRule-ifyag-ai4pp-Page1">
                        <p id="ip0zs" className="m-0 text-center text-white">Copyright © 2024 AI4PublicPolicy Project. All rights reserved.</p>
                    </div>
                </footer>
                <Helmet>
                    <script src="../dist/welcome.js"></script>
                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>
                </Helmet>
            </body>
        </html>
    )
}

export default Welcome;